exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-compare-tsx": () => import("./../../../src/pages/compare.tsx" /* webpackChunkName: "component---src-pages-compare-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-style-guide-tsx": () => import("./../../../src/pages/styleGuide.tsx" /* webpackChunkName: "component---src-pages-style-guide-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-web-templates-article-list-tsx": () => import("./../../../src/web/templates/articleList.tsx" /* webpackChunkName: "component---src-web-templates-article-list-tsx" */),
  "component---src-web-templates-article-tsx": () => import("./../../../src/web/templates/article.tsx" /* webpackChunkName: "component---src-web-templates-article-tsx" */),
  "component---src-web-templates-brand-tsx": () => import("./../../../src/web/templates/brand.tsx" /* webpackChunkName: "component---src-web-templates-brand-tsx" */),
  "component---src-web-templates-gear-list-tsx": () => import("./../../../src/web/templates/gearList.tsx" /* webpackChunkName: "component---src-web-templates-gear-list-tsx" */),
  "component---src-web-templates-gear-tsx": () => import("./../../../src/web/templates/gear.tsx" /* webpackChunkName: "component---src-web-templates-gear-tsx" */),
  "component---src-web-templates-top-tsx": () => import("./../../../src/web/templates/top.tsx" /* webpackChunkName: "component---src-web-templates-top-tsx" */),
  "component---src-web-templates-user-entry-tsx": () => import("./../../../src/web/templates/userEntry.tsx" /* webpackChunkName: "component---src-web-templates-user-entry-tsx" */),
  "component---src-web-templates-user-list-tsx": () => import("./../../../src/web/templates/userList.tsx" /* webpackChunkName: "component---src-web-templates-user-list-tsx" */),
  "component---src-web-templates-user-tsx": () => import("./../../../src/web/templates/user.tsx" /* webpackChunkName: "component---src-web-templates-user-tsx" */)
}

